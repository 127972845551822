/* You can add global styles to this file, and also import other style files */
@import "../styles/colors";
@import "../styles/forms.scss";
@import "../styles/functions.scss";
@import "../styles/products.scss";
@import "../styles/plans.scss";
@import "../styles/dialogs.scss";
@import "../styles/list_table.scss";
@import "~leaflet/dist/leaflet.css";
@import "intro.js/minified/introjs.min.css";
@import "../styles/introjs";

// Poppins font
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

html,
body {
	height: 100%;
}

html.ltr {

	body,
	:not(.bx, mat-icon, .icon, i, .bxs, .rtl, .ltr) {
		font-family: "Poppins", sans-serif !important;
	}
}

@font-face {
	font-family: "Janna";
	src: url("../assets/fonts/Janna-Regular.ttf");
}

@font-face {
	font-family: "URWGeometricArabic";
	src: url("../assets/fonts/URWGeometricArabic-Regular.ttf");
}

body {
	margin: 0;
	font-family: "URWGeometricArabic", sans-serif !important;
	font: "URWGeometricArabic", sans-serif !important;
}

.success {
	background-color: $success-background-color;
	color: $success-color;
}

.warning {
	background-color: $warning-background-color;
	color: $warning-color;
}

.button {
	color: $white !important;
	display: flex;
}

.custom-mat-dialog-container .mat-dialog-container {
	padding: 0 !important;
}

.component-container {
	padding: 16px;
	background-color: $yum-gray-2;
}

.my-app-full-table {
	margin-top: 16px;
	width: 100%;
}

.my-table {
	border-collapse: collapse;
	max-width: 1200px;

	th,
	td {
		border: 1px solid $light-gray-11;
		padding: 8px;
	}

	// tr:nth-child(even) {
	// 	background-color: $light-gray-2;
	// }

	// change the background of every other row
	// to make it easier to read the table
	tr:nth-child(even) {
		background-color: $yum-gray-2;
	}

	tr:hover {
		background-color: $yum-gray-1;
	}

	th {
		padding-top: 12px;
		padding-bottom: 12px;
		text-align: center;
		background-color: $primary-color;
		color: $white;
	}
}

.my-table-total {
	display: flex;
	justify-content: flex-end;
	margin: 10px 0;
	padding: 10px;
	// background-color: $yum-gray-3;
	// border: 1px solid $light-gray-11;
	border-radius: 5px;
}

.my-table-total span {
	margin-inline-start: 10px;
	font-weight: bold;
	font-size: 1.1em;
}

.delete {
	color: $red;
}

// material classes
// dialog wrapper
.cdk-global-overlay-wrapper {
	overflow: auto;
	pointer-events: auto; // needed for chrome but prevents backdrop click close
}

.absolute-right {
	position: absolute;
	right: set-right(20px);
}

.absolute-left {
	position: absolute;
	left: set-left(20px);
}

.accent-color {
	color: $accent-color;
}

.red-color {
	color: $red;
}

button.selected-date-in-date-picker-class {
	div.mat-calendar-body-cell-content.mat-focus-indicator {
		background-color: $accent-color !important;
		border-radius: 100%;
		color: $white !important;
	}
}

.mat-step-icon-content {
	color: $white;
}

.mat-cell {
	text-align: center;
}

.mat-header-cell {
	text-align: center;
}

.my-component-container {
	position: relative;
}

.horizontal-divider {
	border-bottom: 3px solid $light-gray-7;
	width: 100%;
}

.mat-button-toggle-checked {
	background-color: $accent-color;
	color: $white !important;
}

.small-restaurant-logo {
	width: 50px;
	height: 50px;
	border-radius: 8px;
	padding: 8px;
}

/* Styling for the highlighted row */
.highlight-new {
	background-color: $light-pink-background-color;
	border: 1px solid $light-pink-border-color;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
	transition: background-color 0.3s;
}

/* Hover effect for the highlighted row */
.highlight-new:hover {
	background-color: $light-pink-2;
	/* A slightly darker shade of pink on hover */
}

// .mat-header-row {
// 	position: sticky;
// 	top: 77px;
// }
.rtl {
	direction: rtl;
	text-align: right !important;
}

.ltr {
	direction: ltr;
	text-align: left !important;
}

input.rtl {
	font-family: "URWGeometricArabic", sans-serif !important;
}

input.ltr {
	font-family: "Poppins", sans-serif !important;
}

.loading-spinner {
	animation: rotate 2s linear infinite;
	color: $accent-color;
}

.my-note {
	background-color: $light-gray-5; // A light background from your palette
	border-left: 4px solid $primary-color; // Primary color for the border
	color: $paragraph-color; // Paragraph color for text readability
	padding: 15px 20px; // Ample padding for space
	margin: 20px 0; // Margin for spacing around the note
	border-radius: 4px; // Soft rounded corners
	font-family: Arial, sans-serif; // Readable font
	font-size: 16px; // Adequate font size for readability
	line-height: 1.5; // Line height for easier reading
}

.my-note:before {
	content: "\2139"; // Information icon
	color: $primary-color; // Icon color matching the border
	font-size: 24px; // Larger font size for the icon
	vertical-align: middle; // Align icon with the first line of the note
	margin-right: 10px; // Space between icon and text
}

.header-with-separator {
	color: $dark-gray-1;
	margin: 14px 0;
	display: flex;
	justify-content: center;
	align-items: center;

	.icon {
		margin-inline-start: 6px;
		margin-inline-end: 4px;
		margin-bottom: 14px;
		font-size: 20px;
		transform: translateY(5px) rotate(180deg);
	}

	.icon-no-style {
		margin-bottom: 0;
		transform: none;
	}

	span {
		width: 100%;
		font-family: "Poppins", sans-serif;

		&:first-of-type {
			display: inline-block;
			position: relative;

			&::after {
				content: " ";
				position: absolute;
				top: 50%;
				background-color: $light-gray;

				margin-inline-start: 6px;
				width: 100vw;
				box-shadow: 0px 0px 0px 0.2px $light-gray-18;
			}
		}
	}
}

.mat-form-field-required-marker,
span.required {
	color: $red !important;
}

.alert-container,
.warning-container {
	border-radius: 9px;
	padding: 14px 19px !important;
	display: flex;
	flex-direction: row;
	gap: 12px;
	align-items: center;
	margin-block: 10px;

	svg {
		height: 25px;
		width: 25px;
	}

	mat-icon {
		padding-top: 2px;
	}

	i {
		font-size: 25px;
	}
}

.warning-container {
	background-color: $warning-background-color;
	color: $warning-color;

	svg {
		color: $warning-color;
	}
}

.alert-container {
	background-color: $alert-background-color;
	color: $alert-color;

	svg {
		color: $alert-color;
	}
}

.mt-15 {
	margin-top: 15px !important;
}

.table-overflow {
	width: 100%;
	overflow-x: auto;

	table {
		width: 100%;
	}
}

.error-message-text {
	color: $red !important;
}

.success-message-text {
	color: $success-color !important;
}

.date-time {
	direction: ltr;
}

.mat-input-field-remove-hint-spacing {
	.mat-form-field-wrapper {
		padding-bottom: 0 !important;
	}
}

@keyframes rotate {
	100% {
		transform: rotate(360deg);
	}
}

@media screen and (max-width: 800px) {
	.mat-tooltip-trigger {
		touch-action: auto !important;
	}
}