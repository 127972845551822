$paragraph-color: #737373;
$primary-color: #1274bd;
$secondary-color: #1174bd;
$accent-color: #24aae1;
$accent-color-1: #1a273e;
$accent-color-2: #54a8dc;

$accent-bg-color: #1174bd;
$primary-bg-color: #e6f6f5;
$accent-bg-color-2: #d7e8f5;

$yum-gray-1: #999999;
$yum-gray-2: #d9d9d9;
$yum-gray-3: #f8f8f8;
$yum-gray-4: #f6f6f6;
$yum-gray-5: #f1f1f1;
$success-background-color: #ddf0cf;
$success-color: #7ed104;

$premium-background-color: #f7e7ce;
$premium-color: #c49b13;

$warning-background-color: #ffd2d2;
$warning-color: #f83f3f;

$alert-color: #ff5900;
$alert-background-color: #fff2a9;

// sidebar colors

// $sidebar-bg-color: #17171e;
// $sidebar-dark-gray-color: #292932;
// $sidebar-light-2-gray-color: #3d3d46;
// $sidebar-light-gray-color: #b5b5be;

$sidebar-bg-color: #152740;
$sidebar-dark-gray-color: #0b1d35;
$sidebar-light-2-gray-color: #2e4057;
$sidebar-light-gray-color: #ffffffb7;
$sidebar-active-item-color: #24aae1;

$sidebar-white-color: #ffffff;

// new added colors
$accent-hover-color: #1174bd;
$accent-light-color: #ecf7ff;

$light-pink-background-color: #fdf0f5;
$light-pink-border-color: #f9d1e0;

$light-gray: rgba(
	$color: #000000,
	$alpha: 0.12,
);

$black: #091c2e;

// Generic Colors
$white: #ffffff;
$black: #000000;
$red: red;
$green: green;
$yellow: yellow;
$orange: orange;
$gray: gray;

// Grays
// Light Gray Colors - From Lightest shade ($light-gray) to Darkest shade($light-gray-16)
$light-gray: lightgray;
$light-gray-1: #eeeeee;
$light-gray-2: #f2f2f2;
$light-gray-3: #f5f5f5;
$light-gray-4: #f7f7f7;
$light-gray-5: #f9f9f9;
$light-gray-6: #fafafa;
$light-gray-7: #e0e0e0;
$light-gray-8: #e2e2e2;
$light-gray-9: #e5e5e5;
$light-gray-10: #e6e6e6;
$light-gray-11: #ddd;
$light-gray-12: #ccc;
$light-gray-13: #b5b5be;
$light-gray-14: #aaa;
$light-gray-15: #999999;
$light-gray-16: #d0d0d0;
$light-gray-17: #737791;
$light-gray-18: #9a9a9a;
$light-gray-19: #b5b7c0;
$light-gray-20: #f3f3f3;
$light-gray-21: #c8c8c8;

// Dark Gray
$dark-gray: #636363;
$dark-gray-1: #606060;
$dark-gray-2: #333;
$dark-gray-3: #3a3a3a;
$dark-gray-4: #242424;
$dark-gray-5: #161616;
$dark-gray-5: #1d232e;
$dark-gray-6: #1a2a3c;
$dark-gray-7: #25446f;
$dark-gray-8: #2e2e2e;
$dark-gray-9: #232227;
$dark-gray-10: #222223;
$dark-gray-11: #242526;
$dark-gray-12: #292a2b;
$dark-gray-13: #323232;
$dark-gray-14: #292a2a;
// Gray colors with opacity
$dark-gray-opacity-1: #e9e9e93f;
$dark-gray-opacity-2: #acacac3f;
$dark-gray-opacity-3: #000000ad;
$dark-gray-opacity-4: #0000003b;
$light-gray-opacity: #e9e9e93f;

// other shades of gray for quick use
$medium-gray: #8a8a8a;
$medium-gray-1: #878791;
$light-grayish: #b0a9a9;
$grayish-silver: #bab4b4;

// Blues
$dark-blue: #0d1a29;
$dark-blue-hover: #0a1621;
$light-sky-blue: lightskyblue;
$midnight-blue: #3f92c4;
$light-blue: #ccd1f1;
$light-blue-1: #c7e8f5;
$light-blue-2: #05b3fe;
$light-blue-3: #d6f5fb;
$deep-blue: #0b99df;
$sidebar-active-item-color: #1e75ff;
$link-blue: #0a58ca;
$blue: #0a58ca;
$blue-1: #25449f;
$background-blue: #d7e8f6;

$dark-blue-1: #05004e;
$dark-blue-2: #152740;
$dark-blue-3: #19273e;
$dark-blue-4: #0d1f48;
// Greens
$light-green: lightgreen;
$light-green-1: #ddf0d0;
$light-green-2: #ccffcc;
$light-green-3: #9bf49b;
$light-green-4: #32ce0a;
$light-green-5: #85d704;
$forest-green: #587a58;
$dark-green: #28a745;
$dark-green-1: #3f6049;

// Reds
$bright-red: #e90000;
$dark-red: darkred;
$dark-red-1: #dc3545;
$dark-red-2: #bb3c3c;
$light-red-1: #f44;
$background-red: #f8d4d3; // Yellows
$background-yellow: #fbf4ce;
$front-yellow: #fad300;

// Pink
$light-pink-1: #f2e0e5;
$light-pink-2: #fdeaf1;
$light-pink-3: #ffcccb;
$light-pink-4: #f0d0d0;
$light-pink-5: #ffcccc;

// Light Coral
$light-coral: lightcoral;
$light-coral-1: #ff9999;
$light-coral-2: #f49b9b;
$light-coral-3: #fd7c7c;

// Purples
$lavender-mist: #a3959e;
$lavender-gray: #e5dae7;
$lavender-purple: #8a598a;
$deep-purple: #5616bd;
$soft-lavender: #d0c0da;
$dark-lavender: #555770;

// Teals and Turquoise
$light-teal: #d5e1dd;
$teal-blue: #71b6af;
$turquoise-blue: #83cec7;
$soft-teal: #c0d4d0;

// Mixed Colors or Colors with only one shade
$blue-gray-3: #6b7a99;
$dark-plum: #6c2555;
$alice-blue: aliceblue;
$light-yellow: #f1f1cc;
$dark-orange: #bd6116;
$blue-gray: #425166;
$blue-gray-1: #737791;

// Badges, Status, Icons, and etc. Colors
$close-icon-color: #f63b3b;
$close-icon-hover-color: #c31010;
$pending-complaint-bg: #4a90e2;
$in-process-complaint-bg: #f5a623;
$resolved-complaint-bg: #7ed321;
$rejected-complaint-bg: #f44336;

// Borders
$card-border-color: #e0e0e0;
$table-border-color: #e0e0e0;

// Accent Colors
$light-accent-color: #ff329f;
$pink-1: #f54a98;
$pink-2: #f64697bf;
$pink-3: #e63b87;

$active-color: #54a8dc;
$active-bg-color: #d3e9f7;
$active-bg-hover-color: #c5e6f5;
$inactive-color: #606060;
$inactive-bg-color: #f4f4f4;
$inactive-bg-hover-color: #e6e6e6;

$header-bg-color: #152740;
$header-text-color: #ffffff;

$status-colors: (
	pending: (
		light: #fff3cd,
		base: #856404,
		dark: #856404,
		gradient: linear-gradient(45deg, $front-yellow, $light-yellow),
	),
	active: (
		light: #d4edda,
		base: #155724,
		dark: #155724,
		gradient: linear-gradient(45deg, $light-green-5, $light-green-4),
	),
	processing: (
		light: #cce5ff,
		base: #004085,
		dark: #004085,
		gradient: linear-gradient(45deg, $blue, $light-blue-2),
	),
	fulfilled: (
		light: #d4edda,
		base: #155724,
		dark: #155724,
		gradient: linear-gradient(45deg, $dark-gray-7, $light-gray-17),
	),
	canceled: (
		light: #f8d7da,
		base: #721c24,
		dark: #721c24,
		gradient: linear-gradient(45deg, $dark-red-1, $light-red-1),
	),
);
