.introjs-tooltipReferenceLayer * {
  font-family: "URWGeometricArabic", sans-serif !important;
}

.introjs-tooltip {
  background-color: #fff;
  border-radius: 16px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 24px;
}

.introjs-tooltip-title {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 12px;
  color: #333;
}

.introjs-tooltiptext {
  font-size: 14px;
  line-height: 1.6;
  color: #666;
  margin-bottom: 20px;
  padding: 0px;
}

.introjs-button {
  background-color: #3898fc;
  border: none;
  color: white;
  padding: 8px 16px;
  border-radius: 4px;
  text-shadow: unset;

  &:hover {
    background-color: #1976d2;
  }
}

.introjs-tooltipbuttons {
  display: flex;
  align-items: center;
  border-top: none;
  padding: 0;
  justify-content: end;
}

.introjs-tooltip-header {
  display: flex;
  padding: 0px;
  min-height: 1.5em;
  position: unset;

  .introjs-skipbutton.ar {
    left: 0;
    right: unset;
  }
}

.introjs-skipbutton {
  color: #666;
  font-size: 20px;
  background: none;
  border: none;
  cursor: pointer;
  order: -1;
  margin: 4px;
  &:hover {
    color: #333;
  }
}

.introjs-prevbutton {
  background-color: #f5f5f5 !important;
  color: #333 !important;
  margin-right: 8px;

  &:hover {
    background-color: #e0e0e0 !important;
  }
}

.introjs-nextbutton {
  background-color: #3898fc;
  color: white;

  &:hover {
    background-color: #1976d2;
    color: white;
  }
}

.introjs-helperLayer {
  background-color: rgba(255, 255, 255, 0.9);
  border: none;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
}

.introjs-bullets {
  display: none;
}

.introjs-progress {
  margin-bottom: 20px;
}

.tour-tooltip.ar {
  .introjs-tooltiptext {
    text-align: right;
  }

  .introjs-bullets {
    direction: rtl;
  }

  .introjs-skipbutton {
    left: 0;
    right: unset;
  }

  .introjs-prevbutton {
    float: right;
    margin-left: 5px;
    margin-right: 0;
  }

  .introjs-nextbutton {
    float: left;
    margin-right: 5px;
    margin-left: 0;
  }
}
