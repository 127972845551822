@import "../styles/colors.scss";
.product {
	display: flex;
	justify-content: space-between;
	background-color: $yum-gray-3;
	border: 1px solid $light-gray;
	padding: 16px;
	border-radius: 8px;
}
.product-info {
	display: flex;
}
.product-image {
	border-radius: 8px;
	margin-inline-end: 16px;
}

.titles-nutritions-container {
	display: flex;
	flex-direction: column;
}

.product-nutritions {
	display: flex;
}

.product + .product {
	margin-top: 8px;
}

.product-nutritions {
	margin-top: 12px;
}

.calories {
	mat-icon {
		color: $orange;
	}
	margin-inline-end: 12px;
	transform: translateY(-7px);
}

.carbs,
.protein,
.fat {
	margin-inline-start: 8px;
}
@media screen and (max-width: 600px) {
	.product-info {
		flex-direction: column;
	}
}
.products-summary,
.combos-summary {
	margin-bottom: 16px;
}
.products {
	margin-top: 32px;
}
