@import "../styles/colors.scss";
.dialog-rtl {
	direction: rtl !important;
}

.dialog-ltr {
	direction: ltr !important;
}

.dialog-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 14px;
	padding-left: 20px;
	border-bottom: 1px solid $card-border-color;
	background-color: $dark-blue;
	color: $white;
	font-size: 12px;
}

.dialog-container {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;

	.dialog-content {
		padding-inline: 15px;
		padding: 15px;
	}

	.dialog-title {
		color: $dark-blue;
		font-size: 12px;
		font-weight: 600;
	}

	.dialog-description {
		color: $dark-gray;
		margin-bottom: 8px;
		font-size: 12px;
	}

	.dialog-actions {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		margin-block: 16px;
		padding-inline: 16px;
	}

	.cancel-btn,
	.submit-btn {
		cursor: pointer;
		border: none;
		outline: none;
		font-size: 12px;
		font-weight: 500;
		border-radius: 5px;
	}

	.cancel-btn {
		background-color: transparent;
		color: $black;
		box-shadow: none;

		&:hover {
			background-color: $light-gray-1;
			color: $dark-blue;
		}

		&:disabled {
			color: $light-gray;
			background-color: transparent;

			&:hover {
				background-color: transparent;
				cursor: default;
			}
		}
	}

	.submit-btn {
		background-color: $accent-color;
		color: $white;

		&:disabled {
			background-color: $light-gray-2;
			color: $light-gray;

			&:hover {
				background-color: $light-gray-2;
				cursor: default;
			}
		}

		&:hover {
			background-color: $accent-hover-color;
		}
	}

	.loading-spinner {
		color: $medium-gray !important;
	}
}

// Popup / Dialog new design
.mat-dialog-container:has(.pop-up-container) {
	padding: 0px !important;
}
.mat-dialog-container:has(.pop-up-container.full-screen) {
	width: 100% !important;
	margin: 0 auto;
	height: 100%;
}
.mat-dialog-container:has(.pop-up-container.mini) {
	width: 400px !important;
	margin: 0 auto;
}
.mat-dialog-container:has(.pop-up-container.small) {
	width: 300px !important;
	margin: 0 auto;
}

.pop-up-container {
	.pop-up__header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: clamp(1rem, 2.5vw, 1.5rem);
		background: linear-gradient(135deg, $dark-blue-2, $dark-gray-7);
		color: $white;
		position: relative;
		box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);

		&::after {
			content: "";
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			height: 1px;
			background: linear-gradient(
				to right,
				rgba(255, 255, 255, 0),
				rgba(255, 255, 255, 0.1) 50%,
				rgba(255, 255, 255, 0)
			);
		}

		.pop-up__header__title {
			.menu-day-title {
				display: flex;
				align-items: center;
				gap: clamp(0.25rem, 1vw, 0.5rem);
				font-size: clamp(0.9rem, 1.5vw, 1.2rem);
				font-weight: 500;
				letter-spacing: 0.3px;

				.breadcrumb-separator {
					color: rgba(255, 255, 255, 0.5);
					font-size: clamp(0.9rem, 1.5vw, 1.2rem);
					margin: 0 clamp(0.25rem, 1vw, 0.5rem);
				}

				span {
					text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
				}
			}
		}

		.pop-up__header__actions {
			display: flex;
			align-items: center;
			gap: clamp(1rem, 2vw, 1.5rem);

			.actions-group {
				display: flex;
				align-items: center;
				gap: clamp(0.375rem, 1vw, 0.5rem);

				&:first-child {
					margin-right: clamp(0.5rem, 1.5vw, 1rem);
					position: relative;

					&::after {
						content: "";
						position: absolute;
						right: clamp(-0.75rem, -1.75vw, -1.25rem);
						top: 50%;
						transform: translateY(-50%);
						height: 24px;
						width: 1px;
						background: rgba(255, 255, 255, 0.2);
					}
				}
			}

			.category-btn {
				display: flex;
				align-items: center;
				gap: 0.375rem;
				background: rgba(255, 255, 255, 0.1);
				border: none;
				padding: 0 clamp(0.75rem, 1.5vw, 1.25rem);
				height: clamp(32px, 4vw, 38px);
				color: $white;
				font-size: clamp(0.75rem, 1.2vw, 0.875rem);
				border-radius: 6px;
				backdrop-filter: blur(4px);
				transition: all 0.2s ease;

				mat-icon {
					font-size: clamp(16px, 2vw, 20px);
					width: clamp(16px, 2vw, 20px);
					height: clamp(16px, 2vw, 20px);
					opacity: 0.9;

					&:last-child {
						margin-left: -0.25rem;
						font-size: clamp(14px, 1.8vw, 18px);
					}
				}

				&:hover {
					background: rgba(255, 255, 255, 0.15);
					transform: translateY(-1px);
				}
			}

			.cancel-btn {
				color: rgba(255, 255, 255, 0.85);
				font-size: clamp(0.75rem, 1.2vw, 0.875rem);
				padding: 0 clamp(0.75rem, 1.5vw, 1.25rem);
				height: clamp(32px, 4vw, 38px);
				border-radius: 6px;
				transition: all 0.2s ease;

				&:hover {
					background: rgba(255, 255, 255, 0.1);
				}
			}

			.save-btn {
				min-width: clamp(80px, 10vw, 100px);
				font-size: clamp(0.75rem, 1.2vw, 0.875rem);
				height: clamp(32px, 4vw, 38px);
				border-radius: 6px;
				box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
				transition: all 0.2s ease;

				&:hover:not([disabled]) {
					transform: translateY(-1px);
					box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
				}

				&[disabled] {
					opacity: 0.7;
				}
			}
		}

		@media screen and (max-width: 600px) {
			flex-direction: column;
			gap: 1rem;
			align-items: stretch;
			text-align: center;
			padding: clamp(0.75rem, 2vw, 1rem) clamp(1rem, 2.5vw, 1.5rem);

			.menu-day-title {
				justify-content: center;
			}

			.pop-up__header__actions {
				justify-content: center;
				flex-wrap: wrap;
				gap: 0.75rem;

				.actions-group {
					&:first-child {
						margin-right: 0;

						&::after {
							display: none;
						}
					}
				}
			}
		}

		@media screen and (max-width: 400px) {
			.category-btn span {
				display: none;
			}

			.category-btn {
				padding: 0 0.75rem;
				aspect-ratio: 1;
				justify-content: center;

				mat-icon:last-child {
					margin-left: 0;
				}
			}

			.pop-up__header__actions {
				gap: 0.5rem;

				.actions-group {
					gap: 0.375rem;
				}
			}
		}
	}
	.pop-up__content {
		padding-inline: 25px;
		padding-block: 25px;
		min-height: 50px;
		.pop-up__description {
			color: $dark-gray;
			margin-bottom: 8px;
			font-size: 12px;
		}
	}
}
.pop-up__footer-actions {
	display: flex;
	gap: 4px;
	justify-content: flex-end;
	align-items: center;
	margin-top: 20px;
}

.pop-up__action-buttons {
	display: flex;
	gap: 4px;
	align-items: center;
	margin-block: 20px;
}

@media (max-width: 600px) {
	.mat-dialog-container:has(.pop-up-container) {
		min-width: 90% !important;
	}
}
