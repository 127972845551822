@import "../styles/colors.scss";
// To open the ngx timepicker above the current dialog
.timepicker-overlay {
	z-index: 10000000 !important;
}

.timepicker-backdrop-overlay {
	z-index: 10000000 !important;
}
@media (max-width: 800px) {
	.filter-select {
		width: 100px;
	}

	.top-filter {
		flex-direction: column;
	}
}

.close-button {
	position: absolute;

	top: 10px;
	cursor: pointer;
	color: $red;
}
html[dir="rtl"] {
	.close-button {
		left: 10px;
	}
}
html[dir="ltr"] {
	.close-button {
		right: 10px;
	}
}

.mat-dialog-container {
	padding: 30px !important;
	border-radius: 24px !important;
}
.my-dialog-form-container {
	position: relative;
}

.old-image {
	opacity: 0.3; /* Reduce the opacity to show that it will be removed */
	border: 4px solid $red;
}

.my-dialog-form {
	display: flex;
	flex-direction: column;
}

.my-dialog-action-button {
	margin-top: 8px;
}

.row {
	margin: 8px 0;
}
h2 {
	margin-bottom: 30px !important;
}
