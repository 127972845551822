@import "../../node_modules/@angular/material/theming";
$custom-typography: mat-typography-config(
	$font-family: "URWGeometricArabic",
);
@include mat-core($custom-typography);

/* For use in src/lib/core/theming/_palette.scss */
$yumealz-primary: (
	50: #e6f3ff,
	100: #bde0ff,
	200: #90c9ff,
	300: #63b3ff,
	400: #369dff,
	500: #1274bd,
	600: #1067b0,
	700: #0e5ac5,
	800: #0c4e9b,
	900: #0a4271,
	A100: #ffffff,
	A200: #f0ffff,
	A400: #c0e8ff,
	A700: #90d6ff,
	contrast: (
		50: #000000,
		100: #000000,
		200: #000000,
		300: #000000,
		400: #000000,
		500: #000000,
		600: #000000,
		700: #000000,
		800: #000000,
		900: #000000,
		A100: #000000,
		A200: #000000,
		A400: #000000,
		A700: #000000,
	),
);

/* For use in src/lib/core/theming/_palette.scss */
$yumealz-accent: (
	50: #e6f7ff,
	100: #bde5ff,
	200: #90d6ff,
	300: #63c7ff,
	400: #36b8ff,
	500: #146eb0,
	600: #1e96d4,
	700: #1982c7,
	800: #24aae1,
	900: #0f5ab5,
	A100: #ffffff,
	A200: #f0ffff,
	A400: #c0e8ff,
	A700: #90d6ff,
	contrast: (
		50: #000000,
		100: #000000,
		200: #000000,
		300: #000000,
		400: #000000,
		500: #000000,
		600: #000000,
		700: #ffffff,
		800: #ffffff,
		900: #ffffff,
		A100: #000000,
		A200: #000000,
		A400: #000000,
		A700: #000000,
	),
);

$my-primary: mat-palette($yumealz-primary, 800);
$my-accent: mat-palette($yumealz-accent, 800);

$my-theme: mat-light-theme($my-primary, $my-accent);

@include angular-material-theme($my-theme);
