@import "../styles/colors.scss";
app-restaurants-filter {
	mat-form-field {
		margin-inline-end: 0;
	}
}
.my-table-top {
	display: flex;
	flex-direction: column;
	// background-color: #fafafa;
	// box-shadow: 10px 10px 8px #fafafa;
	.top-filter {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 20px;
		align-items: flex-start;
		gap: 12px;
	}
	.filters {
		display: flex;
		flex-wrap: wrap;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
		width: 100%;
		gap: 12px;
	}
	button + .export {
		margin-inline-start: 4px;
	}
}

@media screen and (max-width: 800px) {
	.my-table-top {
		.top-filter {
			flex-direction: column;
			align-items: flex-start;
		}
	}
}

.clear-button {
	background-color: unset;
	border: 0;
	color: $gray;
	text-decoration: underline;
	margin-inline-start: 8px;
}
